// import { Tooltip } from "antd";
"use client";
import React from "react";
import ImageComponent from "../Image/ImageComponent";
import "../../../../styles/card/card.scss";
import DetailsDrawerOpen from "./CardSections/DetailsDrawerOpen";
import UserProfileImage from "../userProfileImage/userProfileImage";
import { LikeContainer } from "..";
import { CountConvert, isEmpty } from "@/src/utils";
import TooltipComponent from "../Tooltip/TooltipComponent";
import Link from "next/link";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Popover,
  //  Skeleton
} from "antd";

const CardComponent = ({
  websiteDataObj,
  handleDrawerOpen,
  handleWebsiteViewCount,
  handleWebsiteCardCollection,
  handleAddCollectionName,
  isMyWebsite,
  handleUserWebsiteDelete,
  handleSaveCollection,
  handleUserWebsiteEdit,
  isScreenShotOpen,
  token,
  collectionError,
  data,
  drawer,
  modal,
  setModal,
  setWebsiteCardData,
  setDrawer,
  manageCollection,
  handleOnMouseHover,
  userOnHoverFilterData,
  // handleOnMouseLeave,
  userPortfolioProfileData,
  isCarousel,
  isPortfolioWebsite,
}) => {
  const getRegionNames = new Intl.DisplayNames(["en"], { type: "region" });

  // const [isImageValid, setIsImageValid] = useState(true);

  // const handleImageValidation = (urlImage) => {
  //   return fetch(urlImage, { method: "HEAD", mode: "no-cors" })
  //     .then((response) => {
  //       if (response.ok) {
  //         const contentType = response.headers.get("Content-Type");
  //         return contentType && contentType.startsWith("image/");
  //       }
  //       return false;
  //     })
  //     .catch(() => false);
  // };

  // useEffect(() => {
  //   if (websiteDataObj?.screenShotImg) {
  //     handleImageValidation(websiteDataObj.screenShotImg).then((isValid) =>
  //       setIsImageValid(isValid)
  //     );
  //   }
  // }, [websiteDataObj?.screenShotImg]);

  return (
    <div className={"paid-card-main"}>
      <div className={"dashboard-image-wrap"}>
        <div className={"image-wrap"}></div>
        <div>
          {/* {!isImageValid ? (
            <Skeleton.Input active className="profile-web-skeleton" />
          ) : ( */}
          <ImageComponent
            imageSrc={`${websiteDataObj?.screenShotImg}`}
            imageAlt={websiteDataObj?.alt || "image"}
            imageClassName={"dashboard-screenshot-image"}
            imageFill={true}
            imageSizes={`(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 33vw`}
          />
          {/* )} */}
        </div>
        <DetailsDrawerOpen
          {...{
            handleDrawerOpen,
            handleWebsiteCardCollection,
            handleAddCollectionName,
            handleSaveCollection,
            isMyWebsite,
            handleUserWebsiteDelete,
            handleUserWebsiteEdit,
            websiteDataObj,
            token,
            manageCollection,
            data,
            drawer,
            modal,
            collectionError,
            setModal,
            setWebsiteCardData,
            setDrawer,
            handleWebsiteViewCount,
          }}
        />
      </div>
      <div className={"paid-screenshot-card-main"}>
        <Popover
          content={
            <div className={` card-popover`}>
              <div className="card-popover-info">
                <Link href={`/${websiteDataObj?.userName}`}>
                  <UserProfileImage
                    classNames={""}
                    profileImage={
                      websiteDataObj?.profileImg
                        ? websiteDataObj?.profileImg
                        : userPortfolioProfileData?.profileImg
                          ? userPortfolioProfileData?.profileImg
                          : ""
                    }
                    firstName={
                      websiteDataObj?.firstName ||
                      userPortfolioProfileData?.firstName
                    }
                    lastName={
                      websiteDataObj?.lastName ||
                      userPortfolioProfileData?.lastName
                    }
                    ImageClassName={"profile-avatar"}
                  />
                </Link>
                <div className="card-popover-name">
                  <Link href={`/${websiteDataObj?.userName}`}>
                    <div className="profile-user-wrap">
                      <h4
                        className={`${"paid-website-name"} ${
                          isScreenShotOpen ? "drawer-website-name" : ""
                        }`}
                      >
                        {websiteDataObj?.firstName ||
                          userPortfolioProfileData?.firstName}{" "}
                        {websiteDataObj?.lastName ||
                          userPortfolioProfileData?.lastName}
                      </h4>
                    </div>
                  </Link>
                  <p>
                    {!isEmpty(websiteDataObj?.country)
                      ? getRegionNames?.of(websiteDataObj?.country)
                      : ""}
                  </p>
                </div>
              </div>
              <div className="user-web-wrap">
                {isPortfolioWebsite ? (
                  <LoadingOutlined className="user-web-loader" />
                ) : (
                  !isEmpty(userOnHoverFilterData) &&
                  userOnHoverFilterData?.map((ele) => (
                    <React.Fragment key={ele?.websiteId}>
                      <Link
                        target="_blank"
                        href={`/view-details/${ele?.websiteId}`}
                        className="user-wrap"
                      >
                        <ImageComponent
                          imageSrc={ele?.screenShotImg}
                          imageAlt={ele?.alt}
                          imageWidth={100}
                          imageHeight={100}
                        />
                      </Link>
                    </React.Fragment>
                  ))
                )}
              </div>
            </div>
          }
          placement="topLeft"
          // open={true}
          trigger={"hover"}
          className={"screenshot-image-information-main"}
          overlayClassName={`${isCarousel ? "carsoul-width" : "card-width"} profile-menus`}
        >
          {!isMyWebsite && (
            <React.Fragment>
              {websiteDataObj?.userName ? (
                <Link
                  href={`/${websiteDataObj?.userName}`}
                  className="profile-image-link"
                  onMouseEnter={() =>
                    handleOnMouseHover(
                      websiteDataObj?.profileId ||
                        userPortfolioProfileData?.profileId,
                      websiteDataObj?.websiteId
                    )
                  }
                  // onMouseLeave={handleOnMouseLeave}
                >
                  <div className={"profile-image-main"}>
                    <UserProfileImage
                      classNames={""}
                      profileImage={
                        websiteDataObj?.profileImg
                          ? websiteDataObj?.profileImg
                          : userPortfolioProfileData?.profileImg
                            ? userPortfolioProfileData?.profileImg
                            : ""
                      }
                      firstName={
                        websiteDataObj?.firstName ||
                        userPortfolioProfileData?.firstName
                      }
                      lastName={
                        websiteDataObj?.lastName ||
                        userPortfolioProfileData?.lastName
                      }
                      ImageClassName={"profile-avatar"}
                    />
                    <h4
                      className={`${"paid-website-name"} ${
                        isScreenShotOpen ? "drawer-website-name" : ""
                      }`}
                    >
                      {websiteDataObj?.firstName ||
                        userPortfolioProfileData?.firstName}{" "}
                      {websiteDataObj?.lastName ||
                        userPortfolioProfileData?.lastName}
                    </h4>
                  </div>
                </Link>
              ) : (
                <div
                  className="profile-image-link"
                  onMouseEnter={() =>
                    handleOnMouseHover(
                      websiteDataObj?.profileId ||
                        userPortfolioProfileData?.profileId,
                      websiteDataObj?.websiteId
                    )
                  }
                >
                  <div className={"profile-image-main"}>
                    <UserProfileImage
                      classNames={""}
                      profileImage={
                        websiteDataObj?.profileImg
                          ? websiteDataObj?.profileImg
                          : userPortfolioProfileData?.profileImg
                            ? userPortfolioProfileData?.profileImg
                            : ""
                      }
                      firstName={
                        websiteDataObj?.firstName ||
                        userPortfolioProfileData?.firstName
                      }
                      lastName={
                        websiteDataObj?.lastName ||
                        userPortfolioProfileData?.lastName
                      }
                      ImageClassName={"profile-avatar"}
                    />
                    <h4
                      className={`${"paid-website-name"} ${
                        isScreenShotOpen ? "drawer-website-name" : ""
                      }`}
                    >
                      {websiteDataObj?.firstName ||
                        userPortfolioProfileData?.firstName}{" "}
                      {websiteDataObj?.lastName ||
                        userPortfolioProfileData?.lastName}
                    </h4>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
          {isMyWebsite && (
            <h4
              className={`${"paid-website-name"} ${
                isScreenShotOpen ? "drawer-website-name" : ""
              }`}
            >
              {websiteDataObj?.agencyName}
            </h4>
          )}
        </Popover>
        {!isMyWebsite && (
          <div className={"like-views-main"}>
            <LikeContainer
              {...{
                token,
                obj: websiteDataObj,
                data,
                setWebsiteCardData,
                setDrawer,
              }}
            />
            <div className={"view-button-main"}>
              <svg
                className={"view_icon"}
                width="20"
                height="12"
                viewBox="0 0 20 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 7C4.6 -1 15.4 -1 19 7"
                  stroke="#9E9EA7"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 11C9.60603 11 9.21593 10.9224 8.85195 10.7716C8.48797 10.6209 8.15726 10.3999 7.87868 10.1213C7.6001 9.84274 7.37913 9.51203 7.22836 9.14805C7.0776 8.78407 7 8.39397 7 8C7 7.60603 7.0776 7.21593 7.22836 6.85195C7.37913 6.48797 7.6001 6.15726 7.87868 5.87868C8.15726 5.6001 8.48797 5.37913 8.85195 5.22836C9.21593 5.0776 9.60603 5 10 5C10.7956 5 11.5587 5.31607 12.1213 5.87868C12.6839 6.44129 13 7.20435 13 8C13 8.79565 12.6839 9.55871 12.1213 10.1213C11.5587 10.6839 10.7956 11 10 11Z"
                  stroke="#9E9EA7"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <TooltipComponent
                {...{
                  tooltipName: "Views",
                }}
              />
              <p className={"like-counter"}>
                {CountConvert(websiteDataObj?.viewCount)}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardComponent;
