export const apiConst = {
  // BASE_URL: "http://192.168.29.24:8000",
  BASE_URL: process.env.BASE_URL,
  BASE_URL_SCREENSHOT: process.env.BASE_URL_SCREENSHOT,
  // BASE_URL_SCREENSHOT: "http://192.168.29.26:9001",
  PUBLISH_KEY_SCREENSHOT: process.env.PUBLISH_KEY_SCREENSHOT,
  SECRET_KEY_SCREENSHOT: process.env.SECRET_KEY_SCREENSHOT,
  ADMIN_SOCKET_ID: process.env.ADMIN_SOCKET_ID,
  GOOGLE_OAUTH_PROVIDER: process.env.GOOGLE_OAUTH_PROVIDER,
  GOOGLE_ANALYTICS_KEY: process.env.GOOGLE_ANALYTICS_KEY,
  HOME_PAGE_URL: process.env.HOME_PAGE_URL,
  REF_URL: `?ref=${typeof window !== "undefined" && window?.location?.host}`,
  FRONTEND_URL: typeof window !== "undefined" && window?.location?.origin,
  USER_GET_WITHOUT_TOKEN_WEBSITE: (payload) =>
    `/api/website/user/withoutAuth/?${new URLSearchParams(payload)}`,
  USER_GET_WITH_TOKEN_WEBSITE: (payload) =>
    `/api/website/user/?${new URLSearchParams(payload)}`,
  GET_WEBSITE_BY_ID: (id) => `/api/website/user/${id}`,
  GET_WEBSITE_BY_ID_WITHOUT_TOKEN: (id) =>
    `/api/website/user/withoutAuth/${id}`,
  USER_LOGIN: `/api/auth/user/login`,
  USER_LOGIN_WITH_GOOGLE: `/api/auth/user/google`,
  USER_REGISTER_WITH_GOOGLE: `/api/auth/user/register`,
  FORGOT_PASSWORD_EMAIL: `/api/auth/forgot-password`,
  FORGOT_PASSWORD: (token) => `/api/auth/set-password?token=${token}`,
  RESET_PASSWORD: `/api/auth/user/reset-password`,
  USER_PROFILE: `/api/profile/user/my-profile`,
  GET_ADVERTISE: `/api/user/ads-spot`,
  GET_NEWS: `/api/news/user`,
  WEBSITE_COUNT: (id) => `/api/website/user/viewCount/${id}`,
  WEBSITE_POST: `/api/website/user/addWebsite`,
  GET_CATEGORY: `/api/category/user`,
  GET_STYLE: `/api/style/user`,
  GET_FONT_ADMIN: `api/fontFamily/admin/allFont`,
  LIKE_WEBSITE: (isLike) => `/api/like/user/${isLike}`,
  CATEGORY_DETAILS_WEBSITE: (id) => `/api/website/user/${id}/websiteByCategory`,
  CATEGORY_DETAILS_WEBSITE_WITHOUT_TOKEN: (id) =>
    `/api/website/user/${id}/websiteByCategory/withoutAuth`,
  TOP_10_WEBSITE: `/api/website/user/top10website`,
  TOP_10_WEBSITE_WITHOUT_TOKEN: `/api/website/user/top10website/withoutAuth`,
  GET_COLLECTION: `/api/collection/user`,
  ADD_COLLECTION: `/api/collection/user/addCollection`,
  GET_FONTS: `/api/fontFamily/user`,
  DELETE_COLLECTION: (id) => `/api/collection/user/${id}`,
  GET_ALL_NOTIFICATION: "/api/notification/user/getAllNotification",
  MARK_ALL_AS_READ: "/api/notification/user/markAllNotificationRead",
  UPDATE_FONTS: `/api/website/user/fontFamily`,
  GET_WEBSITE_USER: (payload) =>
    `/api/website/user/byUser?${new URLSearchParams(payload)}`,
  DELETE_WEBSITE_USER: (id) => `/api/website/user/${id}`,
  UPDATE_WEBSITE_USER: (id) => `/api/website/user/${id}`,
  GET_SCREENSHOT_DETAILS: "/api/websiteScreen/user",
  RESEND_USER_VERIFIED: (email) =>
    `/api/auth/user/receivedEmail?role=user&emailId=${email}`,
  WEBSITE_ALREADY_EXIST: `/api/website/user/websiteExist`,
  PHONE_NUMBER_ALREADY_EXIST: `/api/profile/user/checkPhone`,
  DELETE_ACCOUNT: `/api/auth/user/account-delete`,
  COLLECTION_DELETE: `/api/collection/user/collection`,
  VERIFY_ACCOUNT: (token, email) =>
    `/api/auth/user/verify-email/?role=user&token=${token}&emailId=${email}`,
  USERNAME_CHECK_EXIST: `/api/profile/user/check-username`,
  GET_SOCIAL_SITE: `/api/social-site/user/get-social-site`,
  GET_USER_SESSION: `/api/auth/user/get-last-login`,
  DELETE_PROFILE_IMAGE: "/api/profile/user/profileImg",
  GET_USER_LATEST_WEBSITE: (id) =>
    `/api/profile/user/get-latest-post?profileId=${id}`,
  GET_USER_PORTFOLIO_DETAILS: (userName) =>
    `/api/profile/user/get-user-details?userName=${userName}`,
  POST_USER_PORTFOLIO_GET_IN_TOUCH: (userName) =>
    `/api/profile/user/get-in-touch?mailReceiverUserName=${userName}`,
  GET_USER_PORTFOLIO_WEBSITE_DETAILS: (userName) =>
    `/api/website/user/get-user-websites?userName=${userName}`,
  POST_USER_PORTFOLIO_WEBSITE_DETAILS: (userName) =>
    `/api/profile/user/get-in-touch?mailReceiverUserName=${userName}`,
};
